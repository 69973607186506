<script lang="ts">
type Size = 'sm' | 'md' | 'lg'

export interface ToggleProps {
  label?: string
  help?: string
  color?: string
  size?: Size
  disabled?: boolean
  modelValue?: boolean
  flip?: boolean
}
</script>

<script lang="ts" setup>
/* eslint-disable import/first */
import { defineUi } from '#core/utils/nuxt-ui'

/**
 * UiToggle
 *
 * Wraps Nuxt UI's UToggle
 *
 * @see https://github.com/forged-com/forgd/pull/1276
 */
const props = withDefaults(defineProps<ToggleProps>(), {
  color: 'primary',
  size: 'md',
})

const emit = defineEmits(['update:modelValue', 'change'])

const id = useId()

const ui = computed(() => {
  return defineUi('toggle', {
    inactive: `   bg-neutral-200`,

    // outer (button)
    ring: `       ring-1
                  ring-neutral-700
                  focus-visible:ring-primary-300
                  focus-visible:ring-offset-2
                  `,

    // inner (button > span)
    container: {
      base: `     disabled:bg-neutral-600`,
      inactive: ` !bg-primary-900`,
    },

    // background (button)
    active: props.disabled
      ? 'bg-neutral-400'
      : props.color === 'green'
        ? 'bg-green-500'
        : 'bg-primary-300',
  })
})
</script>

<template>
  <div
    data-ui="UiToggle"
    class="inline-flex items-start select-none gap-2"
    :class="flip ? 'flex-row-reverse' : ''"
  >
    <UToggle
      v-bind="{
        ...$attrs,
        ...$props,
      }"
      :id="id"
      :ui="ui"
      @update:model-value="value => emit('update:modelValue', value)"
      @change="value => emit('change', value)"
    />
    <label
      :for="id"
      class="flex flex-col"
      :class="{
        'text-neutral-400 cursor-not-allowed': props.disabled,
        'cursor-pointer': !props.disabled,
        '-mt-[4px]': props.size === 'sm',
        '-mt-[1px]': props.size === 'md',
        'mt-[0px]': props.size === 'lg',
      }"
    >
      <span
        class="text-md"
        :class="!props.disabled && 'text-neutral-900'"
      >
        <slot :disabled="props.disabled" :active="props.modelValue">{{ label }}</slot>
      </span>
      <span
        class="text-small font-200"
        :class="!props.disabled && 'text-neutral-600'"
      >
        {{ help }}
      </span>
    </label>
  </div>
</template>
